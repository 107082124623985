<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <el-icon>
            <menu />
          </el-icon>
          Courses
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-input
          v-model="query.name"
          placeholder="Course Name"
          class="handle-input mr10"
          @keyup.enter="handleSearch"
          :clearable="true"
        ></el-input>
        <el-button type="primary" @click="handleSearch">
          <el-icon>
            <search />
          </el-icon>
          Search
        </el-button>
        <el-button type="primary" @click="handleCreate" style="float: right">
          <el-icon>
            <plus />
          </el-icon>
          Create a Course
        </el-button>
      </div>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @sort-change="changeSort"
        :default-sort="{ prop: 'CreatedTime', order: 'ascending' }"
      >
        <el-table-column
          prop="name"
          label="Name"
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="Name"
        ></el-table-column>
        <el-table-column prop="title" label="Title"></el-table-column>
        <el-table-column
          prop="grade"
          label="Grade"
          :width="this.$widthRatio * 80 + 'px'"
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="Grade"
        ></el-table-column>
        <el-table-column label="Hours per Lesson" :width="this.$widthRatio * 120 + 'px'">
          <template #default="scope">{{ scope.row.lessonHours }} hours</template>
        </el-table-column>
        <el-table-column label="Lesson Count" :width="this.$widthRatio * 100 + 'px'">
          <template #default="scope">{{ scope.row.lessonCount }}</template>
        </el-table-column>
        <el-table-column label="Hour Rate" :width="this.$widthRatio * 100 + 'px'">
          <template #default="scope">${{ scope.row.unitPrice }}</template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="Status"
          :width="this.$widthRatio * 100 + 'px'"
        ></el-table-column>
        <el-table-column
          prop="updatedTime"
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="UpdatedTime"
          label="Updated Time"
          :width="this.$widthRatio * 120 + 'px'"
          :formatter="$tableCellFormatter.formatDate"
        ></el-table-column>
        <el-table-column
          label="Operation"
          :width="this.$widthRatio * 180 + 'px'"
          align="center"
        >
          <template #default="scope">
            <el-button type="text" @click="handleEdit(scope.$index, scope.row)">
              <el-icon>
                <edit />
              </el-icon>
              Edit
            </el-button>
            <el-button
              type="text"
              class="red"
              @click="handleDelete(scope.$index, scope.row)"
            >
              <el-icon>
                <delete />
              </el-icon>
              Delete
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="itemTotal"
          :page-count="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>

      <el-dialog :title="dialogTitle" v-model="editVisible" width="60%">
        <el-form
          ref="mainForm"
          :model="form"
          :rules="rules"
          :label-width="this.$widthRatio * 150 + 'px'"
        >
          <el-form-item prop="grade" label="Grade">
            <el-select v-model="form.grade" placeholder="Please select...">
              <el-option
                v-for="item in options.gradeList"
                :key="item.value"
                :label="item.description"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="courseType" label="Course Type">
            <el-select v-model="form.courseType" placeholder="Please select...">
              <el-option label="Junior course" value="Junior"></el-option>
              <el-option label="HSC course" value="HSC"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="name" label="Name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item prop="title" label="Title">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item prop="unitPrice" label="Hour Rate">
            <el-input
              type="number"
              step="0.01"
              v-model.number="form.unitPrice"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item prop="lessonHours" label="Hours per Lesson">
            <el-input type="number" step="1" v-model="form.lessonHours"></el-input>
          </el-form-item>
          <el-form-item prop="lessonCount" label="Lesson Count">
            <el-input type="number" step="1" v-model="form.lessonCount"></el-input>
          </el-form-item>
          <el-form-item label="Class Hours">
            {{ this.form.lessonHours * this.form.lessonCount }}
          </el-form-item>
          <el-form-item label="Cost Per Lesson">
            {{ this.form.lessonHours * this.form.unitPrice }}
          </el-form-item>
          <el-form-item label="Description">
            <el-input type="textarea" rows="5" v-model="form.description"></el-input>
          </el-form-item>
          <el-form-item label="Note">
            <el-input type="textarea" rows="5" v-model="form.note"></el-input>
          </el-form-item>
          <el-form-item prop="status" label="Status">
            <el-select v-model="form.status" placeholder="Please select...">
              <el-option label="Normal" value="Normal"></el-option>
              <el-option label="Draft" value="Draft"></el-option>
              <el-option label="Deleted" value="Deleted"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="editVisible = false">Cancel</el-button>
            <el-button type="primary" @click="saveEdit">Save</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getData, putData, postData, deleteData } from "../../service/api";

export default {
  name: "courseList",
  data() {
    return {
      controllerUrl: "/course",
      query: {
        courseId: null,
        name: null,
        title: null,
        orderBy: "CreatedTime",
        orderDirection: "ASC",
        pageIndex: 1,
        pageSize: 20,
      },
      options: {
        gradeList: [],
      },
      tableData: [],
      dialogTitle: "",
      editVisible: false,
      itemTotal: 0,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
      rules: {
        grade: [
          {
            required: true,
            message: "Please select course Grade",
            trigger: "change",
          },
        ],
        courseType: [
          {
            required: true,
            message: "Please select Course Type",
            trigger: "change",
          },
        ],
        name: [
          {
            required: true,
            message: "Please input course Name",
            trigger: "blur",
          },
        ],
        title: [
          {
            required: true,
            message: "Please input course Title",
            trigger: "blur",
          },
        ],
        lessonHours: [
          {
            required: true,
            message: "Please input correct Lesson Hours for the course",
            trigger: "blur",
          },
        ],
        lessonCount: [
          {
            required: true,
            message: "Please input correct lesson count for the course",
            trigger: "blur",
          },
        ],
        unitPrice: [
          {
            required: true,
            message: "Please input correct Hour Rate",
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: "Please select course status",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {
    this.options.gradeList = this.$appSetting.globalConfig.gradeList;
    let sortDataArr = this.$user
      .getProfile(`course_list_sort_${this.$user.getUsername()}`, "CreatedTime|ASC")
      .split("|");
    this.query.orderBy = sortDataArr[0];
    this.query.orderDirection = sortDataArr[1];

    this.loadData();
  },
  methods: {
    loadData() {
      getData(this.controllerUrl, this.query).then((res) => {
        console.log(res);

        let resData = res.result;
        this.tableData = resData.list;
        this.query.pageIndex = resData.pageIndex;
        this.query.pageSize = resData.pageSize;
        this.itemTotal = resData.count;
        this.pageTotal = Math.ceil(resData.count / resData.pageSize);
      });
    },
    handleSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    changeSort(sortData) {
      console.log(sortData);
      if (sortData.order) {
        this.query.orderBy = sortData.prop;
        this.query.orderDirection = sortData.order === "ascending" ? "ASC" : "DESC";

        this.$user.setProfile(
          `course_list_sort_${this.$user.getUsername()}`,
          `${this.query.orderBy}|${this.query.orderDirection}`
        );
      } else {
        this.query.orderBy = "";
        this.query.orderDirection = "";
      }
      this.loadData();
    },
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    handleDelete(index, row) {
      // twice to confirm to delete
      this.$confirm("Are you sure to delete this item?", "Prompt", {
        type: "warning",
      })
        .then(() => {
          console.log(row);
          deleteData(this.controllerUrl, row.courseId).then((res) => {
            if (res.result && res.code === "200") {
              this.$message.success("Delete successfully");
              this.tableData.splice(index, 1);
              //this.loadData();
            } else {
              this.$message.error("Delete failed, error message: " + res.message);
            }
          });
        })
        .catch(() => {});
    },
    handleEdit(index, row) {
      console.log(row);
      this.dialogTitle = "Edit";
      this.idx = index;
      this.form = row;
      this.editVisible = true;
    },
    handleCreate() {
      this.form = { grade: "Year6", courseType: "Junior", status: "Normal" };
      this.dialogTitle = "New";
      this.editVisible = true;
    },
    saveEdit() {
      this.$refs.mainForm.validate((valid) => {
        if (valid) {
          if (this.form.courseId && this.form.courseId.length === 22) {
            putData(this.controllerUrl, this.form.courseId, this.form).then((res) => {
              if (res.result && res.code === "200") {
                this.editVisible = false;
                this.$message.success("Update successfully");
              } else {
                this.$message.error("Update failed, error message: " + res.message);
              }
            });
          } else {
            postData(this.controllerUrl, this.form).then((res) => {
              console.log(res);
              if (res.result && res.code === "200") {
                this.editVisible = false;
                this.$message.success("Create successfully");
                this.form = res.result;
                this.loadData();
              } else {
                this.$message.error("Create failed, error message: " + res.message);
              }
            });
          }
        } else {
          this.$message.error("Validation failed, please correct!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 1.17rem;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
